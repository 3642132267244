import Vue from 'vue'
import App from './App.vue'
import 'animate.css'
import VueI18n from 'vue-i18n'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faBars, faX, faEnvelope, faRepeat } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import messages from './i18n'
library.add(faBars, faX, faFacebook, faLinkedin, faEnvelope, faRepeat)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueI18n)
Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

const i18n = new VueI18n({
  locale: 'en',
  messages
})

new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app')
