<template>
    <div v-scroll="handleScroll" id="jonathan" class="jonathan">
        <div class="image">
        </div>
        <div class="serrure" :style="middleWidth"></div>
        <div class="temoignages">
            <div class="temoignage">
                <div class="texte">
                    <BR/><BR/>{{ $t("temoignages.beproject") }}
                    <p class="sous-texte" v-html="$t('temoignages.beprojectUnder')"></p>
                </div> 
            </div>
            <div class="temoignage">
                <div class="texte">
                    <BR/>{{ $t("temoignages.isis") }}
                    <p class="sous-texte" v-html="$t('temoignages.isisUnder')"></p>
                </div>
            </div>
            <div class="temoignage">
                <div class="texte">
                    {{ $t("temoignages.bopha") }}
                    <p class="sous-texte" v-html="$t('temoignages.bophaUnder')"></p>
                </div>
                <div class="portrait bopha">
                </div>   
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "JonathanPresentation",
  props: [],
  data: function () {
    return {
      width: 100,
    };
  },
  computed: {
    middleWidth: function () {
      return "width:" + this.width + "vw";
    },
  },
  methods: {
    handleScroll(evt, el) {        
        let y = el.getBoundingClientRect().y
        if(y > 500 || y < -500) {
            this.width = 100
        } else if(y < 250 && y > -100) {
            this.width = 0
        } else if(y > 250) {
            this.width = Math.round((y-250)/3)
        } else if(y < -200) {
            this.width = Math.abs(Math.round((y + 200)/3))
        }
    }
  },
};
</script>

<style scoped>
.sous-texte {
    font-size: 0.9rem;
    font-style: italic;
}
.texte {
    font-size: 1.1rem;
}
.serrure {
    background-color: var(--flatui-color1);
    background-repeat: no-repeat;
    background-image: url("../assets/logo.svg");
    background-position-x: center;
    transition: 0.2s;
    height: 500px;
    background-blend-mode: overlay;
}
.portrait {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    min-width: 100px;
    background-size: cover;
    border:2px solid var(--flatui-color1);
    margin-left: 2rem;
}
.bopha {
    background: url("../assets/bopha.png") no-repeat;
    background-size: cover;
}
.jonathan {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 200vw;
    margin-left: -50vw;
    justify-content: center;
    background-color: var(--flatui-color1);
}
.temoignages {
    display:flex;
    flex-direction: column;
    height: 500px;
    width:50vw;
    justify-content: space-evenly;
    align-items: center;
    border-left: 3px solid var(--flatui-color1);
    background-color: var(--flatui-color3);
}
.image {
    background: url("../assets/john.png") no-repeat;
    background-size: contain;
    background-position: 50%;
    height: 500px;
    width: 50vw;
    border-right: 3px solid var(--flatui-color1);
    background-color: var(--flatui-color3);
}
.temoignage {
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 1s;
    background-color: var(--flatui-color3);
    background-image: url("../assets/quote.svg");
    background-repeat: no-repeat;
    background-blend-mode: soft-light;
    padding-left: 50px;
    padding-right: 20px;
    background-position-y: -30px;
}
.temoignage:hover > .portrait{
    transform: rotate(15deg);
}
.temoignage:hover {
    font-size: 1.2rem;
}
@media only screen and (max-width: 500px) {
    .temoignage {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .portrait{
        display: none;
    }
}

</style>