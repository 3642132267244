<template>
  <div class="company" v-scroll="handleScroll">
    <div class="intro">
      <div class="panel left" :style="rightWidth">
        <img width="100px" class="logo" src="../assets/logo.svg" />
        <h1>{{ $t("companyName") }}</h1>
        <h2>{{ $t("slogan") }}</h2>
        <div class="card bb">
          <i class="gg-website"></i>
          <p>
            {{ $t("website") }}
            <span class="duree">1 {{ $t("day") }}</span>
          </p>
        </div>
        <div class="card bb">
          <i class="gg-cast"></i>
          <p>
            {{ $t("webapp") }}
            <span class="duree">1 {{ $t("week") }}</span>
          </p>
        </div>
        <div class="card">
          <i class="gg-smartphone-shake"></i>
          <p>
            {{ $t("mobile") }}
            <span class="duree">2 {{ $t("weeks") }}</span>
          </p>
        </div>
      </div>
      <div class="panel right">
        <div class="catchers">            
          <div v-for="(catcher, index) in $t('catchers')" :key="index">
            <transition
              enter-active-class="animate__animated animate__flipInX"
              leave-active-class="animate__animated animate__flipOutX"
            >
              <div class="right-catchers" v-if="slide == index">
                <Rocket v-if="slide == 0"/>
                <Laptop v-else-if="slide == 2"/>
                <Palette v-else-if="slide == 1"/>
                <Car v-else/>
                <p>{{ $t("catchers")[index] }}</p>                
              </div>
            </transition>
          </div>
        </div>
        <div class="circles">
          <i
            v-for="(catcher, index) in $t('catchers')"
            :key="'star' + index"
            class="gg-asterisk"
            :class="slide == index ? 'selected' : ''"
            @click="slide = index"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Rocket from "./icons/Rocket.vue";
import Car from "./icons/Car.vue";
import Laptop from "./icons/Laptop.vue";
import Palette from "./icons/Palette.vue";
export default {
  name: "IntroComponent",
  components: { Rocket, Car, Laptop, Palette },
  data: function () {
    return {
      loading: true,
      width: 50,
      slide: 0,
    };
  },
  computed: {
    rightWidth: function () {
      return "width:" + this.width + "vw";
    },
  },
  methods: {
    handleScroll: function () {
      //function (evt, el) {
      let width = 50 + window.scrollY / 10;
      if (width > 100) {
        width = 100;
      }
      this.width = width;
      return false;
    },
  },
  mounted: function () {
    setInterval(() => {
      if (this.slide == this.$t("catchers").length - 1) {
        this.slide = 0;
      } else {
        this.slide += 1;
      }
    }, 5000);
  },
};
</script>

<style scoped>
.intro {
  display: flex;
  flex-direction: row;
  width: 200vw;
}
.catchers {
    height: 50vh;
}
.right-catchers > svg {
    max-width: 300px;
}
.panel {
  padding: 2rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-sizing: border-box;
  transition: 0.5s;
}
.catchers>div {
  position: absolute;
  text-align: center;
  width: 45vw;
  font-size: 2rem;
}
.right {
  background-color: var(--flatui-color2);
  width: 50vw;
  border-left: 2px solid var(--flatui-color3);
  display: flex;
  flex-direction: column;
}
.left {
  background-color: var(--flatui-color1);
  width: 50vw;
  border-right: 2px solid var(--flatui-color3);
  align-items: center;
}
.gg-asterisk {
  transition: 1s;
  color: var(--flatui-color4);
  cursor: pointer;
}
.selected {
  transform: scale(2) rotate(180deg);
}
.circles {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  top: 10rem;
}
.card > i {
  color: var(--flatui-color4);
}
.card > p {
  color: var(--flatui-color4);
  padding-top: 1rem;
}
@media only screen and (max-width: 500px) {
  .intro {
    display: flex;
    flex-direction: column;
    width: 100vw;
  }
  .right {
    width: 100vw;
  }
  .left {
    width: 100vw !important;
    padding-top: 1rem !important;
  }
  .catchers div {
    width: 100vw;
  }
  .panel {
    padding: 0px;
  }
}
.gg-website,
.gg-cast,
.gg-smartphone-shake {
  --ggs: 3;
}
</style>
