<template>
  <svg
    xml:space="preserve"
    viewBox="0 0 100 100"
    y="0"
    x="0"
    xmlns="http://www.w3.org/2000/svg"
    id="圖層_1"
    version="1.1"
    style="height: 100%; width: 100%;"
    width="200px"
    height="200px"
  >
    <g
      class="ldl-scale"
      style="transform-origin: 50% 50%; transform: rotate(0deg) scale(0.8, 0.8)"
    >
      <g class="ldl-ani">
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: breathing 1.11111s linear -0.590278s infinite normal forwards running;
            "
          >
            <path
              fill="#f5e169"
              d="M66 47.5c-.4-.5-5.1 0-5.7 0H22.9c-1.8 0-4.9.6-6.5 0 .8-2.5 3.4-3.9 5.7-5 6.5-3.1 13.3-5.7 20.3-7.6 10-2.8 16.1 2 22.5 11.1l1.1 1.5z"
              style="fill: rgb(245, 225, 105)"
            ></path>
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: breathing 1.11111s linear -0.625s infinite normal forwards running;
            "
          >
            <path
              fill="#333"
              d="M62.3 46.2c-5.6-7.9-10.9-12.1-19.6-9.7-1.5.4-3 .9-4.5 1.4-.9.3-1.5 1.1-1.5 2l-.3 5.2c-.1 1.3 1 2.4 2.3 2.4h19.7c.5 0 4.7-.4 5 0l-1.1-1.3z"
              style="fill: rgb(51, 51, 51)"
            ></path>
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: breathing 1.11111s linear -0.659722s infinite normal forwards running;
            "
          >
            <path
              fill="#333"
              d="M25 43.2c-1.6.8-3.4 1.7-4.4 3.1-.4.6 0 1.4.7 1.4 1.4.1 3.1-.2 4.3-.2h8c.5 0 .9-.4.9-.9l.4-6.3c0-.7-.6-1.1-1.2-.9-3 1.2-5.9 2.4-8.7 3.8z"
              style="fill: rgb(51, 51, 51)"
            ></path>
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: 1.11111s linear -0.694444s infinite normal forwards running breathing;
            "
          >
            <path
              fill="#f5e169"
              d="M89.4 54.1c-2.3-3.5-5.9-6.2-9.8-7.6-1.2-.4-2.5-.8-3.8-.9-11.8-.6-23.9 0-35.7 0h-23c-3 0-5.6 2.2-6.1 5.1 0 .1-.1.2-.1.3l-.5 3.8-.8 3.4c0 2.2 1.8 4 4 4H62.7c5.6 0 11.2.2 16.8 0 .6 0 1.2 0 1.8-.1 1.1 0 2.2-.1 3.2-.1 2.1-.1 6.5-.1 6.8-3 .1-.9-.2-1.8-.6-2.6-.4-.8-.8-1.6-1.3-2.3z"
              style="fill: rgb(245, 225, 105)"
            ></path>
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: 1.11111s linear -0.729167s infinite normal forwards running breathing;
            "
          >
            <path
              fill="#e15b64"
              d="M14.2 50.7H11c-.2 0-.3.1-.3.3l-.4 3.3c0 .2.1.5.3.5h3.6c.9 0 1.6-.8 1.6-1.8v-.5c-.1-1-.8-1.8-1.6-1.8z"
              style="fill: rgb(225, 91, 100)"
            ></path>
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: 1.11111s linear -0.763889s infinite normal forwards running breathing;
            "
          >
            <path
              fill="#e0e0e0"
              d="M87.9 51c-.2-.2-.5-.4-.8-.4h-3c-.8 0-1.4.5-1.4 1.1v1.5c0 .8.8 1.6 1.8 1.6h5c.6 0 .7-.5.4-1l-2-2.8z"
              style="fill: rgb(224, 224, 224)"
            ></path>
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: 1.11111s linear -0.798611s infinite normal forwards running breathing;
            "
          >
            <path
              fill="#f8b26a"
              d="M90.3 57.8H9.6s-.4 0-.4.4c0 2.4 1.7 4 4.1 4h77c1.2 0 2.2-1 2.2-2.2 0-1.2-1-2.2-2.2-2.2z"
              style="fill: rgb(248, 178, 106)"
            ></path>
          </g>
        </g>
        <g class="ldl-layer">
          <g class="ldl-ani">
            <g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.833333s infinite normal forwards
                      running breath-acad5284-de01-4612-9a86-8f034692c3f8;
                  "
                >
                  <circle
                    fill="#fff"
                    r="7.3"
                    cy="57.2"
                    cx="25.3"
                    style="fill: rgb(255, 255, 255)"
                  ></circle>
                </g>
              </g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.868056s infinite normal forwards
                      running breath-acad5284-de01-4612-9a86-8f034692c3f8;
                  "
                >
                  <path
                    fill="#333"
                    d="M25.3 51.3c3.2 0 5.8 2.6 5.8 5.8S28.5 63 25.3 63s-5.8-2.6-5.8-5.8 2.6-5.9 5.8-5.9m0-3c-4.9 0-8.8 3.9-8.8 8.8s3.9 8.8 8.8 8.8 8.8-3.9 8.8-8.8-4-8.8-8.8-8.8z"
                    style="fill: rgb(51, 51, 51)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g class="ldl-layer">
          <g class="ldl-ani">
            <g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.902778s infinite normal forwards
                      running breath-acad5284-de01-4612-9a86-8f034692c3f8;
                  "
                >
                  <circle
                    fill="#e6e6e6"
                    r="4"
                    cy="57.2"
                    cx="25.3"
                    style="fill: rgb(230, 230, 230)"
                  ></circle>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g class="ldl-layer">
          <g class="ldl-ani">
            <g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.9375s infinite normal forwards
                      running breath-acad5284-de01-4612-9a86-8f034692c3f8;
                  "
                >
                  <circle
                    fill="#fff"
                    r="7.3"
                    cy="57.2"
                    cx="71.9"
                    style="fill: rgb(255, 255, 255)"
                  ></circle>
                </g>
              </g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.972222s infinite normal forwards
                      running breath-acad5284-de01-4612-9a86-8f034692c3f8;
                  "
                >
                  <path
                    fill="#333"
                    d="M71.9 51.3c3.2 0 5.8 2.6 5.8 5.8S75.1 63 71.9 63s-5.8-2.6-5.8-5.8 2.6-5.9 5.8-5.9m0-3c-4.9 0-8.8 3.9-8.8 8.8s3.9 8.8 8.8 8.8 8.8-3.9 8.8-8.8-3.9-8.8-8.8-8.8z"
                    style="fill: rgb(51, 51, 51)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g class="ldl-layer">
          <g class="ldl-ani">
            <g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -1.00694s infinite normal forwards
                      running breath-acad5284-de01-4612-9a86-8f034692c3f8;
                  "
                >
                  <circle
                    fill="#e6e6e6"
                    r="4"
                    cy="57.2"
                    cx="71.9"
                    style="fill: rgb(230, 230, 230)"
                  ></circle>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: 1.11111s linear -1.04167s infinite normal forwards running breathing;
            "
          >
            <path
              fill="#f8b26a"
              d="M59.1 55.9H41c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h18c.8 0 1.5.7 1.5 1.5.1.8-.6 1.5-1.4 1.5z"
              style="fill: rgb(248, 178, 106)"
            ></path>
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: 1.11111s linear -1.07639s infinite normal forwards running breathing;
            "
          >
            <path
              fill="#f5e169"
              d="M17 43l-7.2-.7c-1.1-.1-2-1-2.1-2.2l-.1-1.5c-.1-1 .9-1.8 1.9-1.5l10.2 3.3c0 1.5-1.3 2.7-2.7 2.6z"
              style="fill: rgb(245, 225, 105)"
            ></path>
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: breathing 1.11111s linear -1.11111s infinite normal forwards running;
            "
          >
            <path
              d="M18.7 46.2l-2.9 2.5-4.9-8.1 1.9-1.6z"
              fill="#f5e169"
              style="fill: rgb(245, 225, 105)"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "CarIcon",
};
</script>

<style>
.ldl-scale {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #25bc32;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #25bc32;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  height: 150px;
  width: 150px;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-weight: 300;
  visibility: visible;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
  transform-origin: 50% 50%;
  transform: rotate(0deg) scale(0.8, 0.8);
}
.ldl-ani {
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-weight: 300;
  visibility: visible;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
}
@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
</style>
