<template>
  <svg
    xml:space="preserve"
    viewBox="0 0 100 100"
    y="0"
    x="0"
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    version="1.1"
    style="height: 100%; width: 100%;"
    width="200px"
    height="200px"
  >
    <g
      class="ldl-scale"
      style="transform-origin: 50% 50%; transform: rotate(0deg) scale(0.8, 0.8)"
    >
      <g class="ldl-ani">
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: 1.11111s linear -0.625s infinite normal forwards running  breathing;
            "
          >
            <path
              stroke="#333"
              fill="#e0e0e0"
              stroke-miterlimit="10"
              stroke-width="3.5"
              d="M30.474 20.457c11.179-8.735 30.487-10.268 37.75 4.339 3.393 6.825 2.886 15.186-.303 22.109-3.19 6.922-8.822 12.501-15.182 16.701-1.053.695-2.166 1.394-2.827 2.469s-.699 2.656.277 3.456c.375.308.847.464 1.294.654 3.818 1.624 5.961 6.397 4.638 10.33-3.045 9.046-16.481 5.067-22.756 2.728-7.205-2.685-14.112-6.778-18.711-12.94-12.27-16.439 2.081-39.11 15.82-49.846z"
              style="fill: rgb(224, 224, 224); stroke: rgb(51, 51, 51)"
            ></path>
          </g>
        </g>
        <g class="ldl-layer">
          <g class="ldl-ani">
            <g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.694444s infinite normal forwards  breathing;
                  "
                >
                  <path
                    stroke-miterlimit="10"
                    fill="#f0af6b"
                    stroke="#333"
                    stroke-width="3.5"
                    d="M54.796 59.728l-6.351-6.351a2.421 2.421 0 0 1 0-3.425l32.558-30.981c.946-.946 2.355-1.069 3.148-.276l5.327 5.327c.793.793.67 2.203-.276 3.148L58.22 59.728a2.42 2.42 0 0 1-3.424 0z"
                    style="fill: rgb(240, 175, 107); stroke: rgb(51, 51, 51)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.763889s infinite normal forwards  breathing;
                  "
                >
                  <path
                    stroke-width="3.5"
                    stroke-linejoin="round"
                    stroke="#333"
                    fill="#65503a"
                    stroke-miterlimit="10"
                    d="M51.605 68.269c-4.148 4.716-14.356 3.284-14.761 8.072-6.016-4.339-3.355-11.56 1.665-17.519 4.44-5.27 8.514-5.624 12.13-3.015 3.617 2.609 5.517 7.287.966 12.462z"
                    style="fill: rgb(101, 80, 58); stroke: rgb(51, 51, 51)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.833333s infinite normal forwards  breathing;
                  "
                >
                  <path
                    d="M81.874 26.298L63.969 44.204"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-width="3.5"
                    stroke="#333"
                    stroke-linejoin="round"
                    fill="none"
                    style="stroke: rgb(51, 51, 51)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.902778s infinite normal forwards  breathing;
                  "
                >
                  <path
                    stroke-miterlimit="10"
                    stroke-width="3.5"
                    stroke="#333"
                    fill="#f3e4c7"
                    d="M54.915 43.795l-6.471 6.158a2.421 2.421 0 0 0 0 3.425l6.351 6.351a2.421 2.421 0 0 0 3.425 0l6.158-6.471-9.463-9.463z"
                    style="fill: rgb(243, 228, 199); stroke: rgb(51, 51, 51)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: 1.11111s linear -0.972222s infinite normal forwards running  breathing;
            "
          >
            <circle
              r="5.239"
              stroke-miterlimit="10"
              fill="#ea7c60"
              stroke-width="3.5"
              stroke="#333"
              cy="27.436"
              cx="49.127"
              transform="rotate(-4.863 49.127 27.433)"
              style="fill: rgb(234, 124, 96); stroke: rgb(51, 51, 51)"
            ></circle>
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: 1.11111s linear -1.04167s infinite normal forwards running  breathing;
            "
          >
            <circle
              r="5.239"
              stroke-miterlimit="10"
              fill="#a8b980"
              stroke-width="3.5"
              stroke="#333"
              cy="38.265"
              cx="35.151"
              transform="rotate(-4.863 35.152 38.264)"
              style="fill: rgb(168, 185, 128); stroke: rgb(51, 51, 51)"
            ></circle>
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: 1.11111s linear -1.11111s infinite normal forwards running  breathing;
            "
          >
            <circle
              r="5.239"
              stroke-width="3.5"
              stroke="#333"
              stroke-miterlimit="10"
              fill="#76a0b7"
              cy="52.352"
              cx="24.081"
              transform="rotate(-4.863 24.082 52.35)"
              style="fill: rgb(118, 160, 183); stroke: rgb(51, 51, 51)"
            ></circle>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "PaletteIcon",
};
</script>

<style>
.ldl-scale {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #25bc32;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #25bc32;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  height: 150px;
  width: 150px;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-weight: 300;
  visibility: visible;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
  transform-origin: 50% 50%;
  transform: rotate(0deg) scale(0.8, 0.8);
}
.ldl-ani {
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-weight: 300;
  visibility: visible;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
}
@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
</style>
