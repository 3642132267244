<template>
  <div id="services" class="services">
    <h1>Our services</h1>
    <div class="tiles">
      <div
        @click="toggleTile(index)"
        v-for="(tileText, index) in $t('services')"
        :key="index"
        :class="{ pile: turnedTiles.includes(index), face: !turnedTiles.includes(index) }"
      >
        {{ turnedTiles.includes(index) ? tileText.face : tileText.pile }}
        <font-awesome-icon class="flipper" icon="fa-solid fa-repeat" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesList",
  props: [],
  data: function () {
    return {
      turnedTiles: [],
    };
  },
  computed: {},
  methods: {
    toggleTile(index) {
      var i = this.turnedTiles.indexOf(index);
      if (i === -1) this.turnedTiles.push(index);
      else this.turnedTiles.splice(i, 1);
    },
  },
};
</script>

<style scoped>
.services {
  padding-top: 2rem;
  font-size: 1.5rem;
  background-color: var(--flatui-color1);
}
.tiles {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 1em;
  padding: 2rem;
}
.tiles div {
  display: flex;
  background-color: var(--flatui-color3);
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
  border: 2px solid var(--flatui-color4);
  border-radius: 10%;
  cursor: pointer;
  transition: 0.4s;
}
.flipper {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 0.9rem;
}
.tiles div:hover {
    border: 4px solid var(--flatui-color4);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.face {
    transform: rotateY(0deg) scale(1);
}
.pile {
  font-size: 1rem;
  background-color: var(--flatui-color2) !important;
  transform: rotateY(360deg) scale(1);
}

</style>
