<template>
  <div class="menu" v-scroll="handleScroll">
    <div class="menu-button">
      <transition
        enter-active-class="animate__animated animate__rotateIn"
        leave-active-class="animate__animated animate__rotateOut"
      >
        <button @click="menuShowed = true" v-if="!menuShowed">
          <font-awesome-icon class="menu-icon" icon="fa-solid fa-bars" />
        </button>
      </transition>
      <transition
        enter-active-class="animate__animated animate__rotateIn"
        leave-active-class="animate__animated animate__rotateOut"
      >
        <button @click="menuShowed = false" v-if="menuShowed">
          <font-awesome-icon class="menu-icon" icon="fa-solid fa-x" />
        </button>
      </transition>
      <div class="themes">
        thèmes |
        <a
          href="#"
          @click.prevent="updateTheme('flatui')"
          :class="theme == 'flatui' ? 'selected' : ''"
          >flatui</a
        >
        |
        <a
          href="#"
          @click.prevent="updateTheme('db')"
          :class="theme == 'db' ? 'selected' : ''"
          >dbz</a
        >
      </div>
      <div class="langues">
        <a
          href="#"
          @click.prevent="updateLang('fr')"
          :class="$i18n.locale == 'fr' ? 'selectedLang' : ''"
        >
          <img src="/img/flags/fr.gif" height="15px" />
        </a>
        <a
          href="#"
          @click.prevent="updateLang('en')"
          :class="$i18n.locale == 'en' ? 'selectedLang' : ''"
        >
          <img src="/img/flags/uk.webp" height="15px" />
        </a>
      </div>
    </div>
    <transition
      enter-active-class="opened_menu"
      leave-active-class="closed_menu"
    >
      <div v-if="menuShowed" class="menu-content">
        <a @click.prevent="scrollToAnchorPoint('vision')" href="#">{{ $t("menu.qui") }}</a>
        <a @click.prevent="scrollToAnchorPoint('jonathan')" href="#">{{ $t("menu.ref") }}</a>
        <a @click.prevent="scrollToAnchorPoint('services')" href="#">{{ $t("menu.services") }}</a>
        <a @click.prevent="scrollToAnchorPoint('contact')" href="#">{{ $t("menu.contact") }}</a>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "FastLaneMenu",
  props: [],
  data: function () {
    return {
      menuShowed: false,
      menuButtonShowed: true,
      theme: "flatui",
    };
  },
  methods: {
    updateLang: function (lang) {
      this.$i18n.locale = lang;
    },
    updateTheme: function (theme) {
      this.theme = theme;
      if (theme == "db") {
        document.documentElement.style.setProperty(
          "--flatui-color1",
          "#1c4595"
        );
        document.documentElement.style.setProperty(
          "--flatui-color2",
          "#e76a24"
        );
        document.documentElement.style.setProperty(
          "--flatui-color3",
          "#5f2e37"
        );
        document.documentElement.style.setProperty(
          "--flatui-color4",
          "#e7e5e8"
        );
      } else if (theme == "flatui") {
        document.documentElement.style.setProperty(
          "--flatui-color1",
          "#2ecc71"
        );
        document.documentElement.style.setProperty(
          "--flatui-color2",
          "#3498db"
        );
        document.documentElement.style.setProperty(
          "--flatui-color3",
          "#27ae60"
        );
        document.documentElement.style.setProperty(
          "--flatui-color4",
          "#ecf0f1"
        );
      }
    },
    handleScroll: function () {
      if (window.scrollY > 0 && this.menuShowed) {
        this.menuShowed = false;
      }
      return false;
    },
    scrollToAnchorPoint(refName) {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "manual";
      }
      this.menuShowed = false;
      let el = document.getElementById(refName);
      setTimeout(() => {
        console.log("scroll ", refName, el.getBoundingClientRect().top, window.scrollY);
        window.scrollTo({top: el.getBoundingClientRect().top, behavior:'smooth'});
      }, 500);
    },
  },
};
</script>

<style scoped>
.menu {
  position: absolute;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  padding: 1rem;
  box-sizing: border-box;
  z-index: 100;
}
.themes {
  left: 1rem;
  position: absolute;
  font-size: 1rem;
  max-width: 30vw;
}
.langues {
  right: 1rem;
  position: absolute;
  font-size: 1rem;
  max-width: 30vw;
}
.langues > a {
  padding-right: 5px;
}
.selectedLang > img {
  border: 4px solid var(--flatui-color3) !important;
}
.themes > a {
  transition: 0.5s;
}
.langues > a > img {
  transition: 0.5s;
  border: 4px solid #ffffff00;
}
.selected {
  font-weight: bold;
  text-decoration: none;
  border: 1px solid var(--flatui-color3);
  padding: 4px;
  background-color: #dddddd22;
  border-radius: 4px;
}
@keyframes open-menu {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.opened_menu {
  animation: open-menu 0.35s;
}
.closed_menu {
  animation: open-menu 0.35s reverse;
}
.menu-icon {
  font-size: 1.2rem;
}
a {
  margin: 1rem;
  transition: 0.2s;
  color: var(--flatui-color4);
  font-style: normal;
  text-decoration: none;
}
.menu-content {
  margin-top: 4rem;
  height: 80vh;
  left: 0;
  right: 0;
  width: 90vw;
  bottom: 0;
  background-color: var(--flatui-color3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 3px solid var(--flatui-color4);
  border-radius: 2%;
}
button {
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  padding: 5px;
  background-color: var(--flatui-color4);
  color: var(--flatui-color3);
  border: 2px solid var(--flatui-color3);
  transition: 0.2s;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
}
button:hover {
  transform: scale(1.2);
  cursor: pointer;
}
.menu-content>a:hover {
  font-size: 2.2rem;
  text-shadow: 10px 10px 10px var(--flatui-color1);
  cursor: pointer;
}
</style>
