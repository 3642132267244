<template>
  <div>
    <div v-if="loading" id="loading">
      <div class="lds-ripple-loaded"></div>
    </div>
    <div class="content" v-show="!loading">
      <Menu />
      <Intro />
      <div id="vision" class="vision">
        <h2 v-html="$t('vision.title')"></h2>
        <p v-html="$t('vision.l1')"></p>
        <p> * * * </p>
        <p v-html="$t('vision.l2')"></p>
        <p> * * * </p>
        <p v-html="$t('vision.l3')"></p>
      </div>
      <Jonathan />
      <Services />
      <div id="contact" class="contact">
        <h1>{{ $t('contact.title') }}</h1>
        <div class="contact-icons">
          <a href="https://www.facebook.com/jonathan.faucher" target="blank">
            <font-awesome-icon icon="fa-brands fa-facebook" />
            <p>Facebook</p>
          </a>
          <a href="https://www.linkedin.com/in/jonathan-faucher-29456875/" target="blank">
            <font-awesome-icon icon="fa-brands fa-linkedin" />
            <p>Linkedin</p>
          </a>
          <a href="mailto:jonathan.faucher@gmail.com">
            <font-awesome-icon icon="fa-solid fa-envelope" />
            <p>Email</p>
          </a>
        </div>
      </div>
      <div class="footer">
        <img width="400px" class="footer-logo" src="../assets/logo.svg">
        <BR/>&copy; The FastLane Company 2022
        <BR/>We didn't track or collect any of your data while visiting this website
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "./Menu.vue";
import Jonathan from "./Jonathan.vue"
import Intro from "./Intro.vue"
import Services from "./Services.vue"

export default {
  name: "FastLaneWebsite",
  components: { Menu, Jonathan, Intro, Services },
  data: function () {
    return {
      loading: true,
      width: 50,
    };
  },
  mounted: function () {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>

<style>
.footer-logo {
  filter: invert(100%);
}
.footer {
  background-color: var(--flatui-color1);
  padding-bottom: 5rem;
  padding-top: 5rem;
}
.contact {
  background-color: var(--flatui-color2);
  padding: 2rem;
}
.contact-icons {
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 1.2rem;
}
.contact-icons>a {
  font-size: 6rem;
  color: var(--flatui-color4);
  transition:1s;
}
.contact-icons>a:hover {
  text-shadow: 1px 1px #000000dd;
}
.contact-icons>a>p {
  font-size: 1rem;
}
.vision,.contact{
  font-size: 1.5rem;
  padding:2rem;
}
.filler{
  background-color: var(--flatui-color1);
  padding-top:20px;
  padding-bottom: 20px;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s
}
.card>p{
  font-size: 1.2rem;
}
.card:hover{
  transform: scale(1.2);
}
.duree {
  font-weight: bold;
  
}
.bb {
  border-bottom: 1px dotted var(--flatui-color4);
}
.lds-ripple-loaded {
  position: absolute;
  border: 4px solid var(--flatui-color4);
  background-color: #ffffff00;
  opacity: 0.5;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: lds-grow 1s;
  animation-fill-mode: forwards;
}
.content {
  background-color: var(--flatui-color3);
  overflow-x: hidden;
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
}
.company {
  overflow: hidden;
}
@keyframes lds-grow {
  0% {
    opacity: 0.5;
  }
  100% {
    width: 100vw;
    height: 100vh;
    border-radius: 0%;
    border: 0px;
    opacity: 1;
    border: 0px;
    background-color: #27ae60;
  }
}
.logo{
  filter: invert(100%);
}

</style>
