<template>
  <div id="app">
    <FastLane/>
  </div>
</template>

<script>
import FastLane from './components/FastLane.vue'

export default {
  name: 'App',
  components: {
    FastLane
  }
}
</script>

<style>
:root {
  --flatui-color1: #2ecc71;
  --flatui-color2: #3498db;
  --flatui-color3: #27ae60;
  --flatui-color4: #ecf0f1;
}
html { position: absolute; left: 0; top: 0; right: 0; bottom: 0; overflow: auto; max-width: 100%; overflow-x: hidden;}
body { width: 100%; max-width: 100%; height: auto; margin: 0px; overflow-x: hidden;}
#app { width: 100%; max-width: 100%; height: auto; }
#app {
  font-family: 'Josefin Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--flatui-color4);
  max-width: 100%;
}
.gg-cast {
    background:
    linear-gradient( to left,
        currentColor 5px,transparent 0)
        no-repeat bottom right/6px 2px,
    linear-gradient( to left,
        currentColor 5px,transparent 0)
        no-repeat top left/2px 2px;
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 20px;
    height: 16px;
    border-top: 2px solid;
    border-right: 2px solid;
    border-radius: 2px;
    border-left: 0
  }
  .gg-cast::after,
  .gg-cast::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 6px double;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 50%;
    transform: rotate(-45deg);
    bottom: -10px;
    left: -10px
  }
  .gg-cast::after {
    width: 4px;
    height: 4px;
    border: 2px solid;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    bottom: -2px;
    left: -2px
  }
  .gg-smartphone-shake {
    background:
        linear-gradient(to left,
            currentColor 5px,transparent 0)
            no-repeat 4px 12px/2px 2px;
    box-sizing: border-box;
    position: relative;
    transform: scale(var(--ggs,1));
    display: block;
    width: 14px;
    height: 20px;
    border: 2px solid;
    border-radius: 2px
  }
  .gg-smartphone-shake::after,
  .gg-smartphone-shake::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    height: 10px;
    width: 6px;
    border: 2px solid transparent;
    overflow: hidden;
    box-shadow: 2px 0 0,
          inset -2px 0 0;
    top: 3px;
    left: -12px
  }
  .gg-smartphone-shake::after {
    transform: scaleX(-1);
    left: 16px
  }
  .gg-website {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 22px;
    height: 18px;
    border: 2px solid;
    border-radius: 3px;
    box-shadow: 0 -1px 0
}

.gg-website::after,
.gg-website::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px;
    top: 2px
}
.gg-website::before {
    background: currentColor;
    left: 2px;
    box-shadow: 0 4px 0,0 8px 0;
    border-radius: 3px;
    height: 2px
}

.gg-website::after {
    height: 10px;
    border: 2px solid;
    right: 2px;
    border-radius: 1px
}
 .gg-asterisk {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 12px;
  height: 12px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  box-shadow: inset 0 0 0 2px
}

.gg-asterisk::after,
.gg-asterisk::before {
 content: "";
 display: block;
 position: absolute;
 box-sizing: border-box;
 width: 2px;
 height: 12px;
 background: currentColor;
 transform: rotate(55deg)
}

.gg-asterisk::after {
 transform: rotate(-55deg)
} 

</style>
