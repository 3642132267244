<template>
  <svg
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    style="height: 100%; width: 100%;"
    width="200px"
    height="200px"
  >
    <g
      class="ldl-scale"
      style="transform-origin: 50% 50%; transform: rotate(0deg) scale(0.8, 0.8)"
    >
      <g class="ldl-ani">
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: breathing 1.11111s linear -0.634921s infinite normal forwards running;
            "
          >
            <path
              fill="#f4e6c8"
              d="M38.283 68.939a5.332 5.332 0 0 0-5.532 1.459c-1.44 1.579-1.751 3.636-1.15 5.396.286.836.232 1.74-.229 2.493l-.075.126c-.537.93-.78 1.909-.741 2.742.06 1.275-.195 2.545-.906 3.605l-.075.112c-.764 1.161-1.667 2.241-2.242 3.51-.585 1.264-1.105 2.565-1.353 4.024l.162.093c1.139-.944 2.006-2.045 2.808-3.184.812-1.133 1.296-2.455 1.919-3.697l.06-.122c.563-1.146 1.534-2.002 2.669-2.588.741-.382 1.467-1.082 2.004-2.013l.062-.11c.426-.774 1.176-1.292 2.043-1.461a5.343 5.343 0 0 0 3.619-2.579 5.372 5.372 0 0 0-3.043-7.806z"
              style="fill: rgb(244, 230, 200)"
            ></path>
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: breathing 1.11111s linear -0.714286s infinite normal forwards running;
            "
          >
            <path
              fill="#f47e5f"
              d="M65.194 52.327L53.331 70.982l.805.464 6.972 5.381 5.834-8.361a6.753 6.753 0 0 0 .531-4.151l-2.279-11.988z"
              style="fill: rgb(244, 126, 95)"
            ></path>
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: breathing 1.11111s linear -0.793651s infinite normal forwards running;
            "
          >
            <path
              fill="#f47e5f"
              d="M26.395 45.057L22.07 54.29l8.146 3.347 1.069.617 10.434-19.919-11.995 4.187a6.747 6.747 0 0 0-3.329 2.535z"
              style="fill: rgb(244, 126, 95)"
            ></path>
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: breathing 1.11111s linear -0.873016s infinite normal forwards running;
            "
          >
            <path
              d="M51.289 59.812l-9.573-5.527L30.86 67.999l13.98 8.072z"
              fill="#666"
              style="fill: rgb(102, 102, 102)"
            ></path>
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: breathing 1.11111s linear -0.952381s infinite normal forwards running;
            "
          >
            <path
              fill="#e0e0e0"
              d="M52.084 20.381L41.719 38.335 31.285 58.254l22.046 12.728 11.863-18.655L75.75 34.044c.214-.371.402-.756.573-1.147L52.791 19.311c-.253.343-.492.698-.707 1.07z"
              style="fill: rgb(224, 224, 224)"
            ></path>
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: breathing 1.11111s linear -1.03175s infinite normal forwards running;
            "
          >
            <path
              fill="#e15c64"
              d="M77.924 13.969a6.216 6.216 0 0 0-4.368-6.203c-1.804-.567-3.775-.195-5.371.82l-12.296 7.823a11.545 11.545 0 0 0-3.098 2.902l23.532 13.586c.571-1.304.903-2.704.964-4.134l.637-14.794z"
              style="fill: rgb(225, 92, 100)"
            ></path>
          </g>
        </g>
        <g class="ldl-layer">
          <g
            class="ldl-ani"
            style="
              transform: scale(0.91);
              transform-origin: 50px 50px;
              animation: breathing 1.11111s linear -1.11111s infinite normal forwards running;
            "
          >
            <circle
              r="5.892"
              fill="#a0c8d7"
              cy="37.939"
              cx="57.535"
              transform="rotate(-60 57.536 37.94)"
              style="fill: rgb(160, 200, 215)"
            ></circle>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "RocketIcon",
};
</script>

<style>
.ldl-scale {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #25bc32;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #25bc32;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  height: 150px;
  width: 150px;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-weight: 300;
  visibility: visible;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
  transform-origin: 50% 50%;
  transform: rotate(0deg) scale(0.8, 0.8);
}
.ldl-ani{
    line-height: 1.5;
    color: #212529;
    text-align: left;
    font-weight: 300;
    visibility: visible;
    font-size: 16px;
    font-family: Roboto, sans-serif;
    box-sizing: border-box;
}
@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
</style>
