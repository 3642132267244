export default { 
    en: {
        companyName: "The FastLane Company",
        slogan: "We build your ideas \n in the blink of an eye",
        website: "Your website in ",
        webapp: "Your web application in ",
        mobile: "Your mobile application in ",
        week: "week",
        day: "day",
        weeks: "weeks",
        menu: {
            qui: "Who are we ?",
            ref: "They trust us",
            services: "Our services",
            contact: "Contact us"
        },
        services: [
            {pile: "Website", face: "Promote an event, a business, a NGO..."},
            {pile: "Web app", face: "ERP, e-commerce, tailored to your need or using on-shelf solution..."},
            {pile: "Mobile app", face: "Android, iOS, hybrid, PWA..."},
            {pile: "System admin", face: "Manage routers and servers under Windows and Linux"},
            {pile: "Audit", face: "Securise and optimize your information system"},
            {pile: "Cloud", face: "Migrate your infrastructure to AWS, Azure, OVH or Google cloud"},
            {pile: "Tech grants to NGO", face: "Get Google and Microsoft grants (worth 5000 euros in services)"},
            {pile: "Infrastructure", face: "Networks, VPN, Security, Servers, Databases..."},
            {pile: "Dev-ops", face: "Continuous integration, automated deployment, step up your dev process."},
            {pile: "Data management", face: "Data science, setup reporting, data-lakes, data-warehouses, ETL"},
        ],
        vision: {
            title: "We are an IT company<BR/>based in <b>Phnom Penh, Cambodia</b><BR/> and <b>Castres, France</b>",
            l1: "Your project needs a <b>maker</b> to prepare a functional prototype in order to <b>visualize</b> and <b>grow</b> your ideas.",
            l2: "No intermediary, no endless design phase.<BR/><BR/> We put <b>individuals</b> and <b>interactions</b> above processes and tools.",
            l3: "No negotiation, no complicated contract. <BR/><BR/> We value collaboration and flexibility to respond to changes.",
        },
        catchers: [
            "Deploy in 1 day so we can get your feedback as soon as possible",
            "IT development is no different from craftsmanship",
            "IT projects doesn't have to be long, costly and disappointing",
            "Using the right technologies allow us to be flexible, fast, efficient"
        ],
        temoignages: {
            title: "They trut us",
            bopha: "The best IT in the world ♥ ",
            bophaUnder: "My wife after I fix her excel file.",
            beproject: "He works like a machine",
            beprojectUnder: "<a href='www.beproject.fr'>Beproject</a>, mission as IT specialist",
            isis: "A talented and commited teacher, always willing to share cutting edge tech knowledge.",
            isisUnder: "<a href='https://isis.univ-jfc.fr/'>ISIS</a> Engineer School"
        },
        contact: {
            title: "Contact us",
        }
    },
    fr: {
        companyName: "La FastLane Compagnie",
        slogan: "Nous matérialisons vos idées en un clin d'oeil",
        website: "Votre site web en ",
        webapp: "Votre application web en ",
        mobile: "Votre application mobile en ",
        week: "semaine",
        day: "jour",
        weeks: "semaines",
        menu: {
            qui: "Qui sommes-nous ?",
            ref: "Nos références",
            services: "Nos services",
            contact: "Nous contacter"
        },
        services: [
            {pile: "Site web", face: "Sites pour promouvoir un évènement, une entreprise, une ONG, ..."},
            {pile: "Application Web", face: "ERP, e-commerce, gestion des stocks, sur mesure ou sur étagère..."},
            {pile: "Application mobile", face: "Android, iOS, hybride, PWA..."},
            {pile: "Administration système", face: "Gestion des serveurs et routeurs sous Windows et Linux"},
            {pile: "Audit", face: "Audit de sécurité et d'optimisation du système d'information"},
            {pile: "Cloud", face: "Migrer votre infrastructure dans le cloud avec AWS, Azure, OVH ou Google cloud"},
            {pile: "Donation tech aux ONG", face: "Montage des dossiers pour obtenir les aides de Microsft et Google (5000 euros de dons en nature)"},
            {pile: "Infrastructure", face: "Mise en place de réseaux, VPN, sécurisation, audit"},
            {pile: "Dev-ops", face: "Intégration continue, déploiement automatisé"},
            {pile: "Data management", face: "Data science, mise en place de rapport, data-lake, data-warehouse, ETL"},
        ],
        vision: {
            title: "Nous sommes une entreprise d'informatique<BR/> basée à <b>Phnom Penh, Cambodge</b><BR/> et <b>Castres, France</b>",
            l1: "Vos projet ont besoin d'un <b>faiseur</b> qui réalisera un prototype fonctionnel qui vous permettra de <b>visualiser</b> et faire <b>grandir</b> vos idées.",
            l2: "Pas d'intermédiaire, pas de phase de conception interminable. <BR/><BR/> Nous mettons les <b>individus</b> et les <b>interactions</b> au dessus des processus et des outils.",
            l3: "Pas de négociation, pas de contrat compliqué. <BR/><BR/> Nous privilégions la collaboration et la flexibilité pour répondre aux changements.",
        },
        catchers: [
            "Deployer en 1 jour nous permet d'adapter le projet à vos feedback immédiatement",
            "L'informatique n'est pas différent de l'artisanat",
            "Les projets informatique n'ont pas à être long, coûteux et décevant",
            "Choisir les bonnes technologies nous permet flexibilité, rapidité, efficacité"
        ],
        temoignages: {
            title: "Ils parlent de nous",
            bopha: "Le meilleur informaticien du monde ♥ ",
            bophaUnder: "Ma femme quand je répare son excel.",
            beproject: "Une vraie machine",
            beprojectUnder: "<a href='www.beproject.fr'>Beproject</a>, mission d'expert technique",
            isis: "Un enseignant talentueux et engagé, toujours volontaire pour partager des connaissances technologiques à la pointe",
            isisUnder: "<a href='https://isis.univ-jfc.fr/'>ISIS</a> Ecole d'ingénieur"
        },
        contact: {
            title: "Contactez-nous",
        }

    }
}