<template>
  <svg
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    style="height: 100%; width: 100%;"
    width="200px"
    height="200px"
  >
    <g
      class="ldl-scale"
      style="transform-origin: 50% 50%; transform: rotate(0deg) scale(0.8, 0.8)"
    >
      <g class="ldl-ani">
        <g class="ldl-layer">
          <g class="ldl-ani">
            <g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.598291s infinite normal forwards running breathing;
                  "
                >
                  <path
                    fill="#666"
                    d="M15.194 70.305V32.014a6.042 6.042 0 0 1 6.042-6.042h57.528a6.042 6.042 0 0 1 6.042 6.042v38.291"
                    style="fill: rgb(102, 102, 102)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.641026s infinite normal forwards running breathing;
                  "
                >
                  <path
                    fill="#fff"
                    d="M19 64.015V33.368a3.382 3.382 0 0 1 3.382-3.382h55.236A3.382 3.382 0 0 1 81 33.368v30.647H19z"
                    style="fill: rgb(255, 255, 255)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.683761s infinite normal forwards running breathing;
                  "
                >
                  <path
                    fill="#e0e0e0"
                    d="M58.627 67.695c-.645 1.248-1.933 2.11-3.435 2.11H44.808c-1.502 0-2.79-.862-3.435-2.11H8.333c0 4.602 3.731 6.333 8.333 6.333h66.667c4.602 0 8.333-1.731 8.333-6.333H58.627z"
                    style="fill: rgb(224, 224, 224)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g class="ldl-layer">
          <g class="ldl-ani">
            <g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.726496s infinite normal forwards running breathing;
                  "
                >
                  <circle
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                    stroke-width="2"
                    stroke="#c33736"
                    fill="#fff"
                    r="10.938"
                    cy="48.063"
                    cx="49.937"
                    style="fill: rgb(255, 255, 255); stroke: rgb(195, 55, 54)"
                  ></circle>
                </g>
              </g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.769231s infinite normal forwards running breathing;
                  "
                >
                  <circle
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                    stroke-width="2"
                    stroke="#c33736"
                    fill="none"
                    r="6.25"
                    cy="48.063"
                    cx="49.937"
                    style="stroke: rgb(195, 55, 54)"
                  ></circle>
                </g>
              </g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.811966s infinite normal forwards running breathing;
                  "
                >
                  <circle
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                    stroke-width="2"
                    stroke="#c33736"
                    fill="#c33736"
                    r="1.563"
                    cy="48.063"
                    cx="49.937"
                    style="fill: rgb(195, 55, 54); stroke: rgb(195, 55, 54)"
                  ></circle>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g class="ldl-layer">
          <g class="ldl-ani">
            <g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.854701s infinite normal forwards running breathing;
                  "
                >
                  <path
                    d="M49.937 48.063l12.344-12.344"
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    stroke="#4a3826"
                    fill="none"
                    style="stroke: rgb(74, 56, 38)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.897436s infinite normal forwards running breathing;
                  "
                >
                  <path
                    d="M60.25 37.75V34"
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    stroke="#4a3826"
                    fill="none"
                    style="stroke: rgb(74, 56, 38)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.940171s infinite normal forwards running breathing;
                  "
                >
                  <path
                    d="M60.25 37.75H64"
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    stroke="#4a3826"
                    fill="none"
                    style="stroke: rgb(74, 56, 38)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -0.982906s infinite normal forwards running breathing;
                  "
                >
                  <path
                    d="M58.375 39.625v-3.75"
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    stroke="#4a3826"
                    fill="none"
                    style="stroke: rgb(74, 56, 38)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -1.02564s infinite normal forwards running breathing;
                  "
                >
                  <path
                    d="M58.375 39.625h3.75"
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    stroke="#4a3826"
                    fill="none"
                    style="stroke: rgb(74, 56, 38)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -1.06838s infinite normal forwards running breathing;
                  "
                >
                  <path
                    d="M56.5 41.5v-3.75"
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    stroke="#4a3826"
                    fill="none"
                    style="stroke: rgb(74, 56, 38)"
                  ></path>
                </g>
              </g>
              <g class="ldl-layer">
                <g
                  class="ldl-ani"
                  style="
                    transform: scale(0.91);
                    transform-origin: 50px 50px;
                    animation: 1.11111s linear -1.11111s infinite normal forwards running breathing;
                  "
                >
                  <path
                    d="M56.5 41.5h3.75"
                    stroke-miterlimit="10"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    stroke="#4a3826"
                    fill="none"
                    style="stroke: rgb(74, 56, 38)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "LaptopIcon",
};
</script>

<style>
.ldl-scale {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #25bc32;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #25bc32;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  height: 150px;
  width: 150px;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-weight: 300;
  visibility: visible;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
  transform-origin: 50% 50%;
  transform: rotate(0deg) scale(0.8, 0.8);
}
.ldl-ani {
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-weight: 300;
  visibility: visible;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
}
@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
</style>
